import { Component } from "react"
import { Container } from '@material-ui/core';
import LoSentimosCard from '../common/LoSentimosCard'
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'

class LoSentimosKba extends Component {
    constructor(props) {
        super(props)
    }

    // function LoSentimosKba({ companyName }) {

    render() {
        const {
            companyName,
            dataInfo
        } = this.props
        return (
            <Container justify="center">
                <LoSentimosCard data={dataInfo} companyName={companyName} />
            </Container>

        )
    }
}

export default withThemeProps(LoSentimosKba, 'VDPNLoSentimosKba')
LoSentimosKba.propTypes = {
    companyName: PropTypes.string,
    dataInfo: PropTypes.object
}
LoSentimosKba.defaultProps = {
    companyName: "Afi",
    dataInfo: {
        "textHeader": "Muchas gracias por tu interés en obtener un préstamo con nosotros.",
        "textBox": " En este momento no pudimos validar tu información, en breve uno de nuestros ejecutivos se pondrá en contacto contigo.",
        "textBoxTwo": " "
    }
}
// export default LoSentimosKba

